import React, { useState, useEffect, useRef } from 'react';
import './styles.css';
import { VictoryChart, VictoryLine, VictoryAxis, VictoryTheme, VictoryTooltip } from 'victory';
import VideoStream from './components/VideoStream';
import TransactionFeed from './components/TransactionFeed';
import { PDFDocument } from 'pdf-lib';
import '@react-pdf-viewer/core/lib/styles/index.css';

const App = () => {
    const [transactionData, setTransactionData] = useState([]);
    const [tpsData, setTpsData] = useState([]);
    const [latencyData, setLatencyData] = useState([]);
    const [blockTimeData, setBlockTimeData] = useState([]);
    const [userId, setUserId] = useState(null);
    const [chatMessages, setChatMessages] = useState([]);
    const [chatInput, setChatInput] = useState('');
    const [botTyping, setBotTyping] = useState('');
    const [liveTransactions, setLiveTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingText, setLoadingText] = useState('Initializing...');
    const [pdfVisible, setPdfVisible] = useState(false);  // Состояние для отображения PDF
    const [pdfUrl, setPdfUrl] = useState('');  // URL PDF для отображения
    const chatBoxRef = useRef(null);

    useEffect(() => {
        fetch('https://api.spacenode.tech/api/generate-user-id/')
            .then(response => response.json())
            .then(data => setUserId(data.user_id))
            .catch(error => console.error('Error fetching user ID:', error));
    }, []);

    useEffect(() => {
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }
    }, [chatMessages]);

    const openLinkInNewTab = (url) => {
        window.open(url, '_blank');
    };

    const generateRandomData = (prevData, maxValue) => {
        return [...prevData.slice(-9), Math.random() * maxValue];
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setTransactionData(prev => generateRandomData(prev, 500));
            setTpsData(prev => generateRandomData(prev, 150));
            setLatencyData(prev => generateRandomData(prev, 50));
            setBlockTimeData(prev => generateRandomData(prev, 800));

            setLiveTransactions(prev => {
                const newTransaction = {
                    id: prev.length + 1,
                    amount: (Math.random() * 100).toFixed(2),
                    timestamp: new Date().toLocaleTimeString(),
                };
                return [...prev.slice(-9), newTransaction];
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const loadingSteps = [
            'Initializing...',
            'Loading components...',
            'Fetching livestream...',
            'Finalizing...'
        ];

        let stepIndex = 0;
        const interval = setInterval(() => {
            setLoadingText(loadingSteps[stepIndex]);
            stepIndex++;

            if (stepIndex === loadingSteps.length) {
                clearInterval(interval);
                setLoading(false);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const typeMessage = (message) => {
        let index = 0;
        setBotTyping('');
        const interval = setInterval(() => {
            setBotTyping(prev => prev + message[index]);
            index++;
            if (index === message.length) {
                clearInterval(interval);
            }
        }, 100);
    };

    const handleSendMessage = () => {
        if (!chatInput.trim() || !userId) return;

        const newMessage = { sender: 'user', text: chatInput };
        setChatMessages([...chatMessages, newMessage]);
        setChatInput('');

        fetch('https://api.spacenode.tech/api/chat/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_id: userId,
                message: chatInput,
            }),
        })
            .then(response => response.json())
            .then(data => {
                const botMessage = { sender: 'bot', text: data.response };
                setChatMessages(prevMessages => [...prevMessages, botMessage]);
                typeMessage(data.response);
            })
            .catch(error => {
                console.error('Error sending message:', error);
                const errorMessage = { sender: 'bot', text: 'Error: Unable to process your message.' };
                setChatMessages(prevMessages => [...prevMessages, errorMessage]);
                typeMessage('Error: Unable to process your message.');
            });
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSendMessage();
        }
    };

    const handlePdfLinkClick = async () => {
        setPdfUrl('/Whitepaper.pdf'); // Укажите путь к вашему PDF
        setPdfVisible(true);

        // Загрузка и рендеринг PDF с использованием pdf-lib
        try {
            const pdfBytes = await fetch('Whitepaper.pdf').then(res => res.arrayBuffer());
            const pdfDoc = await PDFDocument.load(pdfBytes);
            const pages = pdfDoc.getPages();
            const firstPage = pages[0];
            const { height } = firstPage.getSize();

            const canvas = document.getElementById('pdf-canvas');
            const context = canvas.getContext('2d');
            canvas.height = height;
            canvas.width = firstPage.getWidth();

            await firstPage.render({
                canvasContext: context,
                viewport: firstPage.getViewport({ scale: 1 }),
            }).promise;
        } catch (error) {
            console.error('Error loading PDF:', error);
        }
    };

    return (
        <div className="app-container">
            {loading ? (
                <div className="loading-screen">
                    <div className="loading-ring"></div>
                    <div className="loading-text">{loadingText}</div>
                </div>
            ) : (
                <>
                    <div className="video-panel dashboard-item">
                        <div className="video-container">
                            <VideoStream/>
                        </div>
                    </div>

                    <div className="metrics-panel dashboard-item">
                        <div className="graph-links">
                            <a onClick={() => openLinkInNewTab("https://x.com/spacenode_ai")}>X</a>
                            <a onClick={handlePdfLinkClick}>Whitepaper</a>
                            <a onClick={() => openLinkInNewTab("https://pump.fun")}>pump.fun</a>
                        </div>
                        <h3>Chat with Core Terminal</h3>
                        <div className="chat-box" ref={chatBoxRef}>
                            <div className="chat-messages">
                                {chatMessages.map((message, index) => (
                                    <div key={index} className={`chat-message ${message.sender}`}>
                                        <p>{message.text}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="chat-input">
                            <input
                                type="text"
                                value={chatInput}
                                onChange={e => setChatInput(e.target.value)}
                                onKeyDown={handleKeyPress}
                                placeholder="Type your message..."
                            />
                            <button onClick={handleSendMessage}>Send</button>
                        </div>
                    </div>

                    <div className="graphs-panel dashboard-item">
                        <div className="graph-container">
                            {[{
                                title: 'Transaction count',
                                data: transactionData,
                                color: '#e12f49',
                                id: 'transaction-count'
                            },
                                {title: 'TPS', data: tpsData, color: '#27f135', id: 'tps'},
                                {title: 'Latency', data: latencyData, color: '#fdb417', id: 'latency'},
                                {
                                    title: 'Core Temperature',
                                    data: blockTimeData,
                                    color: '#9B64FF',
                                    id: 'block-time'
                                }].map((graph, index) => (
                                <div key={index} className="graph" id={graph.id}>
                                    <h3>{graph.title}</h3>
                                    <VictoryChart theme={VictoryTheme.material} height={250} width={350}>
                                        <VictoryAxis tickValues={[]}
                                                     style={{axis: {stroke: "none"}, grid: {stroke: "lightgray"}}}/>
                                        <VictoryLine data={graph.data.map((value, idx) => ({x: idx + 1, y: value}))}
                                                     style={{data: {stroke: graph.color, strokeWidth: 8}}}
                                                     labels={({datum}) => datum.y.toFixed(2)}
                                                     labelComponent={<VictoryTooltip/>}/>
                                    </VictoryChart>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="transactions-panel dashboard-item metrics-panel">
                        <TransactionFeed transactions={liveTransactions}/>
                    </div>

                    {pdfVisible && (
                        <div className="pdf-overlay">
                            <div className="pdf-container">
                                <embed src="Whitepaper.pdf#view=FitH" width="800px" height="800px"/>
                                <button onClick={() => setPdfVisible(false)}>X</button>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default App;
