import React, { useRef, useEffect } from 'react';

const VideoStream = () => {
    const videoRef = useRef(null);

    useEffect(() => {
        const savedTime = localStorage.getItem('videoCurrentTime');
        if (savedTime && videoRef.current) {
            videoRef.current.currentTime = parseFloat(savedTime);
        }

        // Сохраняем текущее время воспроизведения каждые 1 секунду
        const interval = setInterval(() => {
            if (videoRef.current) {
                localStorage.setItem('videoCurrentTime', videoRef.current.currentTime.toString());
            }
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="video-container">
            <video
                ref={videoRef}
                src="space-stream.mp4"
                autoPlay
                loop
                muted
                style={{ width: '100%', height: 'auto' }}
            />
        </div>
    );
};

export default VideoStream;
