import React, { useEffect, useState } from 'react';

// Функция для генерации случайной транзакции
const generateRandomTransaction = () => {
    const characters = '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';
    const randomTransaction = Array.from({ length: 88 }, () =>
        characters.charAt(Math.floor(Math.random() * characters.length))
    ).join('');

    const currentTime = new Date().toLocaleTimeString(); // Текущее время

    return {
        transactionHash: randomTransaction,
        time: currentTime,
    };
};

const TransactionFeed = () => {
    const [transactions, setTransactions] = useState([]);

    const addRandomTransaction = () => {
        const newTransaction = generateRandomTransaction();
        setTransactions((prevTransactions) => [newTransaction, ...prevTransactions]);
    };

    useEffect(() => {
        const addTransactionWithRandomDelay = () => {
            addRandomTransaction();
            const randomDelay = Math.floor(Math.random() * 1500);
            setTimeout(addTransactionWithRandomDelay, randomDelay);
        };

        addTransactionWithRandomDelay();

        return () => clearTimeout();
    }, []);

    return (
        <div className="transaction-feed">
            <h3>Live Transactions</h3>
            <div className="transactions-list">
                {transactions.slice(0, 10).map((transaction, index) => (
                    <div key={index} className="transaction-card">
                        <p>
                            <strong>Transaction:</strong> {transaction.transactionHash.slice(0, 15)}...{/* Показываем первые 15 символов */}
                        </p>
                        <p>
                            <strong>Time:</strong> {transaction.time}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TransactionFeed;
